import React from "react";
import "../css/font-awesome.css"

const Footer = () => {

  return (
    <footer className="site-footer">
      <div className="footer_inner">
        <div className="container footer-container">
          <div style={{textAlign: "left"}} className="footer-left">
            <div style={{margin:0}} className="site-info footer-text">
              ООО “РЕАЛ”
            </div>
            <div className="site-info footer-text">
              Юридический адрес: 656056
            </div>
            <div className="site-info footer-text">г.Барнаул, пл. им В.Н.Баварина, д.2, оф.900</div>
            {/*<div className="site-info footer-text">Почтовый индекс: 656056</div>*/}
            <div className="site-info footer-text">
              ИНН: 2225154243
            </div>
            <div className="site-info site-info__copy footer-text ">
              ©2022 <a href="https://ooo31.com/" className="dev-link">&laquo;OOO 31&raquo;</a> All rights reserved.
            </div>
          </div>
          <div className="footer-right">
            <span className="footer-title">Контакты:</span>
            <span className="footer-text"><i className="fa fa-map-marker-alt"/>{' '}п. Затон ул.Водников 1е</span><br/>
            <a href="tel:+73852235555" className="footer-text"><i className="fa fa-phone"/>{' '}+7 3852 23-55-55</a><br/>
            <a href="tel:+73852235555" className="footer-text"><i className="fa fa-phone"/>{' '}+7 964 087-55-55</a><br/>
            <a className="footer-link" href="https://www.instagram.com/accounts/login/?next=/perviykurort/">instagram:@perviykurort</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
