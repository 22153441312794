/**
 * Компонент макета, который запрашивает данные
 * с помощью статического запроса Gatsby
 *
 * Подробнее: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import Helmet from "react-helmet";
import favicon from "../images/favicon.ico"
import { useLocation } from '@reach/router';

const Layout = ({
                  children,
                  metaContent,
                  title,
                  keywords
}) => {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{title}</title>
        <html lang="ru"/>
        <meta name="robots" content="all"/>
        <meta name="description" content={metaContent}/>
        <meta name="keywords" content={keywords}/>
      </Helmet>
      <Header/>
      <div className="site-wrapper">
        <main className="site-main">{children}</main>
      </div>
      <Footer/>
    </>
  )
}

/**Проверка типов*/
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
