import {Link} from "gatsby"
import React from "react"
import "../css/font-awesome.css"
import "bootstrap/dist/css/bootstrap.css"
import "../css/style.css"
import logo from "../images/logo.png"
// import { useAuthValue } from "./Auth/AuthContext";
// import { navigate } from "@reach/router";

const Header = () => {
  // const { currentUser } = useAuthValue();

  return (
    <header className="site-header">
      <div className="container container__header">
        <div className="row">
          {/**Логотип*/}
          <div className="col-sm-12 col-md-4 align-self-center header-row">
            <Link className="header-logo" to="/"><img src={logo} alt="logo" width="100px" height="87px"></img></Link>
          </div>
          <div className="col-sm-12 col-md-8 align-self-center header-row cart-wrap">
            {/**Ссылка на прайс*/}
            <div className="header-btn">
              <Link className="header-login" to="/price">
                <i className="fa fa-file" />
                <span className="header-txt">Прайс</span>
              </Link>
            </div>
            {/**Переход на Курорт Первый*/}
            <div className="header-btn">
              <a className="header-login" href="https://nazaton.ru">
                <i className="fa fa-home" />
                <span className="header-txt">Курорт Первый</span>
              </a>
            </div>
            {/*/!**Кнопка авторизации*!/*/}
            {/*<div className="header-btn">*/}
            {/*  {*/}
            {/*    !currentUser ? (*/}
            {/*      <a className="header-login" href="http://localhost:3000/">*/}
            {/*        <i className="fa fa-user" />*/}
            {/*        <span className="header-txt">Войти</span>*/}
            {/*      </a>*/}
            {/*    ) : (*/}
            {/*      <a*/}
            {/*        href="/"*/}
            {/*        onClick={event => {*/}
            {/*          event.preventDefault()*/}
            {/*          localStorage.removeItem('user');*/}
            {/*          window.location.reload();*/}
            {/*          navigate(`/`);*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        Выйти*/}
            {/*      </a>*/}
            {/*    )*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </header>
    )
}

export default Header
